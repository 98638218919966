<template>
  <div class="h-full">
    <div class="bg-mask"></div>
    <div class="bg-circles"></div>
    <div class="relative m-auto overflow-y-scroll scrollbar-hide h-full mx-4 z-10">
      <div class="back-btn-wrapper" style="position: relative; right : -24px">
        <button class="back-btn" @click="()=> this.$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="7.41" height="12" viewBox="0 0 7.41 12">
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(-8.59 -6)" fill="#fff"/>
          </svg>
        </button>
      </div>
      <div class="comment_wrapper mb-32">
        <p class="header_comment">در خواست ها</p>
        <div class="comment" >
          <img :src="baseUrl + data.user.profile" alt="">
          <div class="comment__detail">
            <h6>{{data.user.first_name + ' ' +data.user.last_name}}</h6>
            <div class="mt-4"><span class="inline p-0 text-right">شماره تماس</span> <strong class="font-medium text-sm mr-4">{{data.contact}}</strong></div>
            <div class="mt-4"><span class="inline p-0 text-right">زمان بازدید</span> <strong class="font-medium text-sm mr-4">{{data.visit_time}}</strong></div>
            <div class="mt-4"><span>توضیحات ملک</span>
              <div class="text-white text-sm mt-2">{{data.description}}</div>
            </div>
            <div class="text-left text-sm opacity-50">{{data.created_at}}</div>
            <h6 class="text-sm">مکان روی نقشه</h6>
            <div class="mt-4" style="width: 100%; height: 200px;">
              <mapir :apiKey="mapToken" :mapStyle="this.$store.state.map.options.style" :zoom="10" :center="marker">
                <mapMarker
                    :coordinates.sync="marker"
                    color="blue"
                    :draggable="false"
                >
                  <template slot="marker">
                    <img id="icon-map" src="../../assets/images/icons/ejare.png" alt="">
                  </template>
                </mapMarker>
              </mapir>
            </div>
            <div>
              <div class="btn-notif">
                <a @click="handleSeen" class="seen cursor-pointer">{{data.status === 'waiting' ? 'میخوام بازدید کنم' : 'تایید شده برای دیدن'}}</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import {mapir, mapMarker} from "mapir-vue";

export default {
  name: "NotificationsDetail",
  components :{
    mapir,
    mapMarker
  },
  data(){
    return{
      data : null,
      mapToken : this.$store.state.map.token,
      marker : [],
      baseUrl : this.$store.state.general.baseURL
    }
  },
  methods:{
    handleSeen(){
      if (this.data.status === 'waiting'){
        axios.put('/ad-requests/'+this.$route.params.id,{
          status : 'accepted'
        })
        this.data.status = 'accepted'
      }else{
        this.$toast.error('شما قبلا انتخاب کرده اید که بازدید می کنید')
      }
    },
  },
  created() {
    axios.get('/ad-requests/'+this.$route.params.id).then(({data})=>{
      this.data = data.data;
      this.marker = [ data.data.geoposition.longitude ,data.data.geoposition.latitude ]

    })
  }
}
</script>

<style scoped>

.comment_wrapper .comment__detail span{
  padding: 0 !important;
  display: inline !important;
  text-align: right !important;
}
.btn-notif{
  display: flex;
  justify-content: center;
}
.btn-notif a{
  margin-top: 1rem;
  padding: 0.7rem 1rem;
  text-align: center;
  font-size: 13px;
  border: 1px solid #FF7400;
  border-radius: 1rem;
}
.btn-notif .seen{
  margin-left: 0.5rem;
  background: #644B92;
}
#icon-map{
  border: none !important;
  width: 2.5rem !important;
  height: 2.5rem;
}
</style>